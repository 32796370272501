<!-- 他人代付页面 -->
<template>
	<div>
		<div class="top-bg">
			<div class="top-title flex ">
				<img src="../../assets/images/logo-icon.png" alt="">
				<div class="tips-bg flex f-ai-c">
					<span>您的好友{{prescriptionInfo.name}}请求为其支付一笔订单-瑞云医</span>
				</div>
			</div>
			<div class="friend flex f-jc-sb f-ai-c">
				<span>好友</span>
				<span>{{prescriptionInfo.name}}</span>
			</div>
		</div>
		<div class="wrap">
			<!-- 订单信息 -->
			<div class="order-info">
				<span class="order-info-title">订单信息</span>
				<div class="order-info-content">
					<ul>
						<li>
							<span>订单号</span>
							<span>{{prescriptionInfo.orderId}}</span>
						</li>
						<li>
							<span>订单金额</span>
							<span>￥{{prescriptionInfo.totalFee}}</span>
						</li>
						<li>
							<span>订单状态</span>
							<span>{{prescriptionInfo.status}}</span>
						</li>
						<li v-if="prescriptionInfo.transactionId">
							<span>支付流水号</span>
							<span>{{prescriptionInfo.transactionId}}</span>
						</li>
						<li v-if="prescriptionInfo.createdAt">
							<span>支付时间</span>
							<span>{{prescriptionInfo.createdAt}}</span>
						</li>
						<li v-if="prescriptionInfo.createdAt">
							<span>支付微信账号</span>
							<span>无</span>
						</li>
					</ul>
				</div>
				<div class="pay">
					代付金额：
					<span>
						￥<i>{{(prescriptionInfo.totalFee).toString().split('.')[0]}}</i>
						<i v-if="(prescriptionInfo.totalFee).toString().split('.')[1]">.{{(prescriptionInfo.totalFee).toString().split('.')[1]}}</i>
					</span>
				</div>
			</div>
			<!-- 付款说明 -->
			<ul class="pay-tips" v-if="prescriptionInfo.status === '待支付'">
				<li>付款说明：</li>
				<li>1、付款前请务必和好友再次确认，避免诈骗行为；</li>
				<li>2、如果发生退款，钱将退还到你的微信账户里。</li>
			</ul>
			<!-- 平台推广 -->
			<ul class="pay-tips" v-if="prescriptionInfo.status === '已完成支付'">
				<li>瑞云医是一个在线诊疗平台，您可以与医生进行一对一沟通，药品在线支付，邮寄到家。</li>
				<li>
					搜索微信公众号：
					<span>【北京瑞安堂中医馆】</span>，进行咨询吧！
				</li>
			</ul>
		</div>
		<div class="btn-content flex f-ai-c f-jc-c">
			<div @click="gotoPay" class="active" v-if="prescriptionInfo.status === '待支付'">
				<span>支付</span>
			</div>
			<span v-if="prescriptionInfo.status === '已完成支付'" class="pay-success flex f-ai-c">
				<img src="../../assets/images/yqs-r.png"/>已完成支付
			</span>
		</div>
		<div class="shadow" v-if="showShadow"></div>
	</div>
</template>
<script>
import { orderInfo, getWeChatPayParamsDf } from '@/request/api/user';

const BASE_API = {
	development: 'http://localhost:3000/',
	test: 'https://api-st.odat.shenshenle.cn/',
	production: 'https://api.odat.shenshenle.cn/'
};

export default {
	name: 'ruiyunyiPay',
	data () {
		return {
			daifuId: '',
			totalFee: 0,
			checked: false,
			showShadow: false,
			openid: '',
			prescriptionInfo: {
				totalFee: 0
			}
		};
	},
	created () {
		this.openid = this.$route.query.openid;
		this.daifuId = this.$route.query.id;
		if (!this.openid) {
			const returnUrl = encodeURIComponent(`${window.location.href}`);
			window.location.href = `${BASE_API[process.env.NODE_ENV || 'production']}v1/auth/wechat-auth?returnUrl=${returnUrl}&accountType=PATIENT`;
		} else {
			if (this.$route.query.paied === '1') {
				this.showShadow = true;
				this.$root.elMsg('支付结果查询中', 'fail', () => {
					this.showShadow = false;
					this._getInfo();
				}, 3000);
			} else {
				this._getInfo();
			}
		}
	},
	methods: {
		_getInfo () {
			orderInfo({ id: this.daifuId }).then(data => {
				if (data) {
					this.prescriptionInfo = data;
				}
			});
		},
		gotoPay () {
			getWeChatPayParamsDf({ daifuId: this.daifuId, openid: this.openid }).then(data => {
				if (data && data === 'retry') {
					this.$toast('调取支付失败，请刷新页面重试');
				} else if (data && data.appId) {
					if (typeof WeixinJSBridge === 'undefined') {
						if (document.addEventListener) {
							document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
						} else if (document.attachEvent) {
							document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
							document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
						}
					} else {
						this.onBridgeReady(data);
					}
				} else if (data && data.orderId) {
					this.$root.replace({
						path: '/payment/ruiyunyi-pay',
						query: {
							paied: '1',
							openid: this.openid,
							id: this.daifuId
						}
					});
				}
			});
		},
		onBridgeReady (data) {
			if (typeof WeixinJSBridge !== 'undefined') {
				// eslint-disable-next-line no-undef
				WeixinJSBridge.invoke('getBrandWCPayRequest', data, (res) => {
					if (res.err_msg === 'get_brand_wcpay_request:ok') {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						// res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						window.location.href = `${window.location.href}&paied=1`;
						/* this.$root.replace({
							path: '/payment/ruiyunyi-pay',
							query: {
								paied: '1',
								openid: this.openid,
								id: this.daifuId
							}
						}); */
					} else {
						this.$toast(res.errMsg || '取消支付');
					}
				});
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.top-bg {
		background: url('../../assets/images/consultation.png') no-repeat;
		height: 150px;
		padding: 30px 12px 0 12px;
		position: relative;
		.top-title {
			font-family: PingFangSC-Regular, PingFang SC;
			img {
				width: 60px;
				height: 60px;
			}
			.tips-bg {
				background: url('../../assets/images/tips-bg.png') no-repeat;
				background-size: cover;
				width: 286px;
				height: 60px;
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				padding-left: 25px;
				padding-left: 25px;
				padding-right: 20px;
				box-sizing: border-box;
			}
		}
		.friend {
			position: absolute;
			width: 351px;
			height: 55px;
			background: #FFFFFF;
			box-shadow: 0px 4px 18px -4px rgba(16, 31, 46, 0.04);
			border-radius: 8px;
			bottom: -17px;
			padding: 0 15px;
			font-weight: 500;
			font-family: PingFangSC-Medium, PingFang SC;
			span:nth-child(1) {
				font-size: 13px;
				color: #7D7D7D;
			}
			span:nth-child(2) {
				font-size: 15px;
				color: #232830;
			}
		}
	}
	.wrap {
		overflow: scroll;
		height: 456px;
		padding-bottom: 10px;
		.order-info {
			width: 351px;
			min-height: 202px;
			background: #FFFFFF;
			box-shadow: 0px 4px 18px -4px rgba(16, 31, 46, 0.04);
			border-radius: 8px;
			margin-top: 28px;
			padding: 0 15px;
			&-title {
				height: 44px;
				line-height: 44px;
				width: 100%;
				border-bottom: 1px solid #F3F3F3;
				display: inline-block;
				font-size: 13px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #7D7D7D;
				margin-bottom: 15px;
			}
			&-content {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #3B3D40;
				ul {
					li {
						display: flex;
						justify-content: space-between;
						margin-bottom: 15px;
						span:nth-child(2) {
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	.btn-content {
		position: fixed;
		bottom: 0;
		height: 57px;
		background: #FFFFFF;
		width: 100%;
		display: flex;
		align-items: center;
		div {
			width: 100%;
			margin: 0 17px;
			padding: 13px 0;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 21px;
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 12px;
			opacity: 0.5;
			display: flex;
			align-items: center;
			justify-content: center;
			&.active {
				opacity: 1;
			}
		}
		.pay-success {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FF6450;
			img {
				width: 16px;
				height: 16px;
				margin-right: 6px;
			}
		}
	}
	.pay {
		width: 100%;
		background: #FFFFFF;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid #F3F3F3;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #232830;
		span {
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #F33D3A;
			margin-left: 5px;
		}
		i {
			&:nth-child(2) {
				font-size: 11px;
			}
		}
	}
	.pay-tips {
		margin-top: 15px;
		font-size: 12px;
		font-weight: 400;
		color: #7D7D7D;
		li {
			margin-bottom: 4px;
			span {
				color: #FF6450;
			}
		}
	}
	.shadow {
		position: absolute;
		background: rgba(35, 40, 48, 0.78);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
</style>