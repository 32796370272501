var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top-bg" }, [
      _c("div", { staticClass: "top-title flex " }, [
        _c("img", {
          attrs: { src: require("../../assets/images/logo-icon.png"), alt: "" },
        }),
        _c("div", { staticClass: "tips-bg flex f-ai-c" }, [
          _c("span", [
            _vm._v(
              "您的好友" +
                _vm._s(_vm.prescriptionInfo.name) +
                "请求为其支付一笔订单-瑞云医"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "friend flex f-jc-sb f-ai-c" }, [
        _c("span", [_vm._v("好友")]),
        _c("span", [_vm._v(_vm._s(_vm.prescriptionInfo.name))]),
      ]),
    ]),
    _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "order-info" }, [
        _c("span", { staticClass: "order-info-title" }, [_vm._v("订单信息")]),
        _c("div", { staticClass: "order-info-content" }, [
          _c("ul", [
            _c("li", [
              _c("span", [_vm._v("订单号")]),
              _c("span", [_vm._v(_vm._s(_vm.prescriptionInfo.orderId))]),
            ]),
            _c("li", [
              _c("span", [_vm._v("订单金额")]),
              _c("span", [
                _vm._v("￥" + _vm._s(_vm.prescriptionInfo.totalFee)),
              ]),
            ]),
            _c("li", [
              _c("span", [_vm._v("订单状态")]),
              _c("span", [_vm._v(_vm._s(_vm.prescriptionInfo.status))]),
            ]),
            _vm.prescriptionInfo.transactionId
              ? _c("li", [
                  _c("span", [_vm._v("支付流水号")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.prescriptionInfo.transactionId)),
                  ]),
                ])
              : _vm._e(),
            _vm.prescriptionInfo.createdAt
              ? _c("li", [
                  _c("span", [_vm._v("支付时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.prescriptionInfo.createdAt))]),
                ])
              : _vm._e(),
            _vm.prescriptionInfo.createdAt
              ? _c("li", [
                  _c("span", [_vm._v("支付微信账号")]),
                  _c("span", [_vm._v("无")]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "pay" }, [
          _vm._v(" 代付金额： "),
          _c("span", [
            _vm._v(" ￥"),
            _c("i", [
              _vm._v(
                _vm._s(_vm.prescriptionInfo.totalFee.toString().split(".")[0])
              ),
            ]),
            _vm.prescriptionInfo.totalFee.toString().split(".")[1]
              ? _c("i", [
                  _vm._v(
                    "." +
                      _vm._s(
                        _vm.prescriptionInfo.totalFee.toString().split(".")[1]
                      )
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.prescriptionInfo.status === "待支付"
        ? _c("ul", { staticClass: "pay-tips" }, [
            _c("li", [_vm._v("付款说明：")]),
            _c("li", [_vm._v("1、付款前请务必和好友再次确认，避免诈骗行为；")]),
            _c("li", [_vm._v("2、如果发生退款，钱将退还到你的微信账户里。")]),
          ])
        : _vm._e(),
      _vm.prescriptionInfo.status === "已完成支付"
        ? _c("ul", { staticClass: "pay-tips" }, [
            _c("li", [
              _vm._v(
                "瑞云医是一个在线诊疗平台，您可以与医生进行一对一沟通，药品在线支付，邮寄到家。"
              ),
            ]),
            _vm._m(0),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "btn-content flex f-ai-c f-jc-c" }, [
      _vm.prescriptionInfo.status === "待支付"
        ? _c("div", { staticClass: "active", on: { click: _vm.gotoPay } }, [
            _c("span", [_vm._v("支付")]),
          ])
        : _vm._e(),
      _vm.prescriptionInfo.status === "已完成支付"
        ? _c("span", { staticClass: "pay-success flex f-ai-c" }, [
            _c("img", {
              attrs: { src: require("../../assets/images/yqs-r.png") },
            }),
            _vm._v("已完成支付 "),
          ])
        : _vm._e(),
    ]),
    _vm.showShadow ? _c("div", { staticClass: "shadow" }) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(" 搜索微信公众号： "),
      _c("span", [_vm._v("【北京瑞安堂中医馆】")]),
      _vm._v("，进行咨询吧！ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }